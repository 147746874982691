.Testimonials{
    height: 550px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    background-color: white;
   color: var(--text-color-dark);
   
    
}

.testimonial__content{
    display: flex;
    align-items: center;
    justify-content: space-evenly;
   width: 1300px;
    position: relative;
   
  
}


.Testimonials img{
    width: 300px;
    margin: 0 24px;
}
.Testimonials .testimonial__profile {
    position: relative;
 
}

.Testimonials .testimonial__profile .quote__div{
    position: absolute;
    right: 0;
    background: var(--text-color-dark);
    width: 100px;
    height: 100px;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 100%;
    transform: scale(0);
    transition: transform 1.5s ease;
   
}

.Testimonials .testimonial__profile .quote__div.is-visible {
    transform: scale(1);
  }
#quote{
    font-size: 34px;
}

.testimonial__texts{
    max-width: 800px;
    width: 100%;
    
    color: var(--text-color-dark);
    
    
}

.testimonial__texts p{
    line-height: 1.8;
    margin-bottom: 24px;
    font-size: 17px;
    font-weight: 200;
    font-family: 'Bricolage Grotesque';

  

 
}


cite{
    font-style: 400;
   
    font-size: 18px;
    
    color: var(--text-color-dark);
    font-family: 'Poppins';
    
}

.stars{
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin: 14px 0 24px 0;
}
.star{
    color: rgb(250, 167, 0);
    font-size: 22px;
    margin: 0 4px 0 0;
}

#arrow-left{
    left: 0;
    font-size: 20px;
    position: absolute;
    border-radius: 100%;
    background-color: var(--secondary-color);
    color: var(--text-color-white);
    padding: 12px;
    cursor: pointer;
  
}
#arrow-right{
    right: 0;
    font-size: 20px;
    position: absolute;
    border-radius: 100%;
    background-color: var(--secondary-color);
    color: var(--text-color-white);
    padding: 12px;
    cursor: pointer;
   
}
#profile{
    border-radius: 100%;
    height: 300px;
    width: 300px;
}

/* Media Queries */

/* Small desktops */
@media (min-width: 1200px) and (max-width: 1439px) {
    .testimonial__content{
        width: 1200px;
    }
    #profile{
        width: 250px;
        height: 250px;
    }
    .Testimonials .testimonial__profile .quote__div{
        width: 90px;
        height: 90px;
    }
    .testimonial__texts p{
        font-size: 16px;
    }
    .testimonial__texts{
        max-width: 750px;
    }
  }
/*  Landscape Tablets */
@media (min-width: 992px) and (max-width: 1199px) {
    .testimonial__content{
        width: 980px;
        flex-direction: column;
        text-align: center;
        
        
    }
    .Testimonials{
        height: 800px;
        padding: 12px;
    }
    .stars{
        justify-content: center;
    }
    

   
        
}
/* Medium devices (phones) */
@media (min-width: 768px) and (max-width: 991px) {
    .testimonial__content{
        width: 980px;
        flex-direction: column;
        text-align: center;
        
        
    }
    .Testimonials{
        height: 800px;
        padding: 12px;
    }
    .stars{
        justify-content: center;
    }
    #profile{
        width: 250px;
        height: 250px;
    }
   
}
/* Small devices (phones) */
@media (max-width: 767px) {
    .testimonial__content{
        width: 980px;
        flex-direction: column;
        text-align: center;
        
        
    }
    .Testimonials{
        height: 100%;
        padding: 12px;
        margin: 24px 0;
        
    }
    .Testimonials p{
        font-size: 10px;
    }
    .stars{
        justify-content: center;
       
    }
    .stars .star{
        font-size: 16px;
    }
    #profile{
        width: 150px;
        height: 150px;
        margin: 0 24px;
    }
    #arrow-left{
        top: 0;
        font-size: 12px;
        padding: 8px;
    }
    #arrow-right{
        top: 0;
        font-size: 12px;
        padding: 8px;
    }
    .Testimonials cite{
        font-size: 11px;
    }
    .Testimonials .testimonial__profile .quote__div{
        width: 60px;
        height: 60px;
        margin-right: 4px;
    }
}