.Results {
  text-align: center;
  margin-top: 24px;
  width: 100%;
  height: 100%;
  color: var(--text-color-dark);
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
}
.Results h1 {
  font-size: 56px;
  font-weight: 600;
}
.Results p {
  text-align: center;
  font-size: 18px;
  font-weight: 300;
  max-width: 1600px;
  line-height: 2;
}

.Results span {
  color: var(--secondary-color);
  font-weight: 800;
}

.Results .cases {
  display: grid;
  grid-template-columns:repeat(2,1fr); 
  grid-auto-flow:dense;
  margin-top: 16px;
  max-width: 1600px;
  width: 100%;
  margin-bottom: 2rem;
}



.Results .cases .case {
  height: 600px;
  width: 100%;

  display: flex;
  align-items: center;
  justify-content: center;
  background-color: grey;
  
  box-shadow: 0px 2.98px 7.46px 0px rgba(0, 0, 0, 0.1);
  position: relative;
 
  overflow: hidden;
  transition: box-shadow 0.3s ease;
}
.Results .cases .case .case-content img{

  object-fit: cover ;
  height: 600px;
  width: 100%;
}

.case-content {
  position: relative;
  width: 100%;
  height: 100%;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  opacity: 0;
  transition: opacity 0.5s ease;
  color: var(--text-color-white);
}
.overlay button {
  font-size: 14px;
  font-weight: 300;
  padding: 8px 32px;
  border: 1px solid white;
  background-color: transparent;
  margin-top: 32px;
}
.overlay h1 {
  font-weight: 500;
}
.overlay h2 {
  font-weight: 300;
}
.case:hover {
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
}

.case:hover .overlay {
  opacity: 1;
}

.overlay p {
  color: white;
  text-align: center;
}

/* Media Queries */

/* Small desktops */
@media (min-width: 1200px) and (max-width: 1439px) {
  .Results .cases .case {
    height: 400px;
    width: 100%;
  }
  .Results h1 {
    font-size: 52px;
  }
  .Results span {
    font-size: 52px;
  }
  .Results .cases .case .case-content img{

   
    height: 400px;
    width: 100%;
  }
}
/*  Landscape Tablets */
@media (min-width: 992px) and (max-width: 1199px) {
  .Results .cases .case {
    height: 350px;
    width: 100%;
  }
  .Results p {
    max-width: 900px;
    line-height: 2;
  }
  .Results h1 {
    font-size: 42px;
  }
  .Results span {
    font-size: 42px;
  }
  .Results .cases .case .case-content img{

   
    height: 350px;
    width: 100%;
  }
}

/* Medium devices (phones) */
@media (min-width: 768px) and (max-width: 991px) {
  .Results .cases .case {
    height: 300px;
    width: 100%;
  }
  .Results .cases{
    grid-template-columns:repeat(1,1fr); 
  }
  .Results p {
    max-width: 900px;
    font-size: 16px;
    line-height: 2;
  }
  .Results h1 {
    font-size: 28px;
  }
  .Results span {
    font-size: 36px;
  }
  .Results .cases .case .case-content img{

   
    height: 300px;
    width: 100%;
  }
}
/* Small devices (phones) */
@media (max-width: 767px) {
  .Results {
    height: 100%;
  }
 
  .Results .cases .case {
    height: 350px;
    width: 100%;
  }
  .Results .cases .case .case-content img{

   
    height: 350px;
    width: 100%;
  }
  .Results p {
    font-size: 12px;
    max-width: 330px;
    width: 100%;
    line-height: 2;
    margin-top: 24px;
  }
  .Results h1 {
    font-size: 24px;
  }
  .Results span {
    font-size: 24px;
  }
}
