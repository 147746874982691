.Result {

  color: var(--text-color-dark);
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  height: max-content;
 

}

.result__banner p{

  color: white;
  font-size: 18px;
  font-weight: 200;
 
 
}

.result-texts{
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}

.bullet__points p {
  margin-top: 18px;
  margin-bottom: 18px;
  text-align: start;
  margin-left: 52px;
  font-size: 18px;
  font-weight: 200;
  line-height: 2;
  font-family: 'Inter';
  
}
.Result .result__banner {
  width: 100%;
  height: 100%;
  position: relative;
}

.Result .result__banner img {
  height: 600px;
  width: 100%;
  object-fit: cover;
 
  z-index: 8;
}
.result-overlay{
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.464);
  overflow: none;
  top: 0;
 
}
.Result .result__banner h1 {

  font-weight: 600;
  font-size: 92px;
  font-family: 'Poppins';
 
  color: white;
  
}
.bullet__points{
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
    padding: 24px;
   
}
.bullet{
    color: var(--secondary-color);
}
.bullet__points:last-child{
    margin-bottom: 48px;
}

/* Medium devices (phones) */
@media (min-width: 768px) and (max-width: 991px) {
    .Result .result__banner h1 {
        font-size: 56px; 
    }
    .Result p{
        font-size: 18px;
    }
}
/* Small devices (phones) */
@media (max-width: 767px) {
    .Result .result__banner h1 {
        font-size: 28px;
    }
    .Result .result__banner img{
        height: 400px;
    }
    .Result p{
        font-size: 12px;
        max-width: 400px;
       
        margin-left: 0;
    }
  
}