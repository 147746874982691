.boxes {
  display: grid;
  grid-template-rows: auto auto;
  grid-template-columns: auto auto;
  gap: 10px;
}

.boxes .box {
  height: 300px;
  width: 300px;
  font-size: 48px;

}

.box-1 {
  /* border-top-left-radius: 30%; */
  background-image: url("../../../assets/Overview/pic1.webp");

  background-size: cover;
}

.box-2 {
  /* -right-radius: 30%; */
  background-image: url("../../../assets/Overview/pic2.webp");
  background-size: cover;
}

.box-3 {
  /* border-bottom-left-radius: 30%; */
  background-image: url("../../../assets/Overview/pic3.webp");
  background-size: cover;
}

.box-4 {
  /* border-bottom-right-radius: 30%; */
  background-image: url("../../../assets/Overview/pic4.webp");
  background-size: cover;
}

.box-1-color {
  background-color: rgba(223, 16, 16, 0.574);
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  color: white;
  font-weight: 600;
  text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.2);
  position: relative;
  /* border-top-left-radius: 30%; */
}

.box-2-color {
  background-color: rgba(223, 16, 196, 0.458);
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  color: white;
  font-weight: 600;
  text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.2);
  position: relative;

  /* border-top-right-radius: 30%; */
}

.box p {

  text-align: center;
  font-weight: 200;
  font-size: 17px;
  text-shadow: none;
  position: absolute;
  bottom: 0;
  font-family: 'Bricolage Grotesque';
  margin-bottom: 12px;
  padding: 0 0.3rem;

}

.box-3-color {
  background-color: rgba(16, 61, 223, 0.574);
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  color: white;
  font-weight: 600;
  text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.2);
  position: relative;
  /* border-bottom-left-radius: 30%; */
}

.box-4-color {
  background-color: rgba(25, 168, 6, 0.574);
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  color: white;
  font-weight: 600;
  text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.2);
  position: relative;
  /* border-bottom-right-radius: 30%; */
}

.overview__wrapper {
  background: linear-gradient(to bottom, #fbfbfb, #2684ff10, #2684ff10);
  display: flex;
  align-items: center;
  justify-content: center;
}

.overview {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  margin-top: 48px;
  margin-bottom: 32px;
  color: var(--text-color-dark);
  width: 100%;
  max-width: 1550px;
  height: 700px;
  /* box-shadow: 0px 2.98px 7.46px 0px rgba(0, 0, 0, 0.1);
    background-color: #fff; */
}

.overview__texts {
  max-width: 510px;
}

.overview__texts p {
  font-size: 20px;
  line-height: 2;
  font-weight: 200;
  font-family: 'Bricolage Grotesque';

}

.overview__texts h1 {
  font-size: 56px;
  font-weight: 600;
  margin-bottom: 16px;
  font-family: 'Poppins';
}

.overview__texts button {
  margin-top: 32px;
  background-color: var(--secondary-color);
  padding: 12px 32px;
  color: white;
  font-size: 20px;
  font-weight: 400;
  font-family: 'Bricolage Grotesque';
  /* border-top-right-radius: 20px; */
}

/* Media Queries */

/* Small desktops */
@media (min-width: 1200px) and (max-width: 1439px) {}

/*  Landscape Tablets */
@media (min-width: 992px) and (max-width: 1199px) {
  .box p {
    font-size: 12px;
  }

  .boxes .box {
    width: 200px;
    height: 200px;
  }

  .overview__texts h1 {
    font-size: 42px;
  }

  .overview__texts {
    max-width: 480px;
  }

  .overview__texts p {
    font-size: 18px;
    line-height: 2;
  }
}

/* Medium devices (phones) */
@media (min-width: 768px) and (max-width: 991px) {
  .box p {
    font-size: 10.5px;
  }

  .overview {
    height: 100%;
  }

  .boxes .box {
    width: 150px;
    height: 150px;
    font-size: 30px;
  }

  .overview__texts h1 {
    font-size: 36px;
  }

  .overview__texts {
    max-width: 380px;
  }

  .overview__texts p {
    font-size: 16px;
    line-height: 2;
  }
}

/* Small devices (phones) */
@media (max-width: 767px) {
  .box p {
    font-size: 10px;

  }

  .overview {
    flex-direction: column-reverse;
    height: 100%;
  }

  .boxes .box {
    width: 150px;
    height: 150px;
    font-size: 30px;
    margin-top: 24px;
  }

  .overview__texts h1 {
    font-size: 24px;
    text-align: center;
  }

  .overview__texts {
    max-width: 350px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }

  .overview__texts p {
    text-align: center;
    font-size: 14px;
    line-height: 2;
  }

  .overview button {
    margin-top: 12px;
    padding: 10px 24px;

    font-size: 14px;
  }
}