.mobile-menu-overlay {
  position: fixed;
  top: 0;
  left: 100%;
  height: 100vh;
  width: 100%;
  background-color: white;
  z-index: 999;
  transition: left 0.7s ease-in-out;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  margin-top: 45px;
  box-shadow: -1px 0px 7px 0px rgba(0, 0, 0, 0.2);
}
.mobile-menu-overlay p {
  font-size: 20px;
  font-weight: 300;
  margin-top: 32px;
}

.mobile-menu-overlay.show {
  left: 0;
}

.mobile-menu-overlay.hide {
  left: 100%;
}
/* Small devices (phones) */
@media (max-width: 767px) {
  .mobile-menu-overlay p {
    font-size: 16px;
  }
}
