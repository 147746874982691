.Chat{
    background: url("../../../assets/main-assets/Shiny\ Overlay.svg");
    height: 200px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-size: cover;
}

.Chat h1{
    font-size: 38px;
    font-weight: 700;
    color: var(--text-color-white);
}

.Chat button{
    font-weight: 400;
    font-size: 18px;
    border: 2px solid white;
    
    padding: 8px 38px;
    color: var(--text-color-white);
    margin-left: 32px;
   

}
.other__chat button span{
    text-align: center;
}

.other__chat{
    height: 450px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
}
.other__chat video{
    object-fit: cover;
    width: 100%;
    height: 100%;
    position: relative;
}
.other__chat button{
    position: absolute;
    background: var(--secondary-color);
    color: white;
    bottom: 0;
    margin-bottom: 24px;
    padding: 8px 38px;
    font-size: 18px;
    font-weight: 400;
    
 
    display: flex;
    align-items: center;
    justify-content: center;
}
.other__chat span{
    font-size: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 12px;
    font-weight: 900;
}
.other__chat h1{
    position: absolute;
    font-size: 38px;
    color: var(--text-color-white);
    font-weight: 600;
    text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.8);
}

/* Media Queries */

/* Small desktops */
@media (min-width: 1200px) and (max-width: 1439px) {
    .Chat h1{
        font-size: 34px;
    }
   
  }
/*  Landscape Tablets */
@media (min-width: 992px) and (max-width: 1199px) {
    .Chat h1{
        font-size: 24px;
    }
    .other__chat h1{
        font-size: 36px;
    }
  }
/* Medium devices (phones) */
@media (min-width: 768px) and (max-width: 991px) {
    .Chat h1{
        font-size: 24px;
    }
    .other__chat h1{
        font-size: 32px;
    }
}
/* Small devices (phones) */
@media (max-width: 767px) {
    .Chat{
        flex-direction: column;
    }
    .Chat h1{
        text-align: center;
        font-size: 16px;
        font-weight: 500;
    }
    .other__chat h1{
        font-size: 20px;
        text-align: center;
    }
    .other__chat button{
        font-size: 14px;
    }
    .other__chat button span{
        font-size: 14px;
    }
    .Chat button{
        margin: 16px 0;
        font-size: 12px;
    }
    .other__chat{
        height: 300px;
    }
}