.CaseMain{
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
}
.CaseMain img{
    height: 700px;
    width: 100%;
    object-fit: cover;

   
    
}

.CaseMain h1{
    position: absolute;
    text-align: center;
    color: var(--text-color-white);
    font-weight: 600;
    font-size: 128px;
    font-family: 'Poppins';
    
    
    padding: 8px;
}

.results-overlay{
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.464);
    overflow: none;
    top: 0;
   
}
/* Medium devices (phones) */
@media (min-width: 768px) and (max-width: 991px) {
    .CaseMain h1{
        font-size: 78px;
    }
}
/* Small devices (phones) */
@media (max-width: 767px) {
    .CaseMain h1{
        font-size: 48px;
    }
}

