.LearnMore {
  height: 900px;
  background: url("../../../assets/main-assets/bg-rec.svg");
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  color: var(--text-color-dark);
  background-size: cover;
}

.LearnMore h1 {
  font-size: 56px;
  font-weight: 600;
  text-align: center;
  font-family: 'Poppins';
}
.LearnMore p{
  font-family: 'Bricolage Grotesque';
  font-weight: 200;
}

.LearnMore .learn__texts {
  font-size: 20px;
  font-weight: 300;
  text-align: center;
  margin-top: 24px;
  line-height: 2;
}

.solution__boxes {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 16px;
}

.solution__box {
  background: white;
  width: 420px;
  height: 470px;
  margin: 24px 12px;
  padding: 32px;
  color: var(--text-color-dark);
  border: 0.2px solid rgb(206, 206, 206);
}

.solution__box h2 {
  font-size: 26px;
  font-weight: 700;
  position: relative;
  display: inline-block;
  margin-bottom: 50px;
  color: var(--text-color-dark);
}

.solution__box li {
  display: flex;
  align-items: center;
}
.solution__box li a {
  margin-left: 8px;
}
#check {
  color: #16cc6b;
  font-size: 19px;
}

.underlined-text::after {
  /* add underline under h2 */

  content: "";
  position: absolute;
  bottom: -12px;
  left: 0;
  width: 0;
  height: 3.3px;
  background-color: #16cc6b;
  transition: width 7s ease;
}

.underlined-text.is-visible::after {
  width: 152px;
}

.list-style-arrow li {
  margin: 12px 0;
  font-weight: 200;
  font-family: 'Bricolage Grotesque';
  font-size: 16px;
}

.solution__box button {
  margin-top: 18px;
  background-color: #16cc6b;
  color: var(--text-color-white);
  padding: 12px 48px;
  font-family: 'Bricolage Grotesque';
}

/* Media Queries */

/* Small desktops */
@media (min-width: 1200px) and (max-width: 1439px) {
  .solution__box {
    width: 100%;
    height: 480px;
  }
  .solution__box h2 {
    font-size: 22px;
  }
  .LearnMore h1 {
    font-size: 52px;
  }
  .solution__box li a {
    font-size: 16px;
  }
}

/*  Landscape Tablets */
@media (min-width: 992px) and (max-width: 1199px) {
  .solution__box {
    width: 100%;
    height: 500px;
    margin: 4px 2px;
    padding: 18px;
  }
  .solution__box h2 {
    font-size: 20px;
  }
  .LearnMore h1 {
    font-size: 42px;
  }
  .solution__box li a {
    font-size: 16px;
  }
  .LearnMore .learn__texts {
    font-size: 18px;
  }
}
/* Medium devices (phones) */
@media (min-width: 768px) and (max-width: 991px) {
  .LearnMore h1 {
    font-size: 36px;
  }
  .LearnMore p {
    font-size: 16px;
  }

  .solution__box {
    height: 500px;
    width: 240px;
    padding: 12px;
    margin: 4px 6px;
  }
  .solution__box h2 {
    font-size: 16px;
  }
  .solution__box li a {
    font-size: 14px;
  }

  .solution__box button {
    padding: 8px 32px;
    font-size: 14px;
  }
  .underlined-text::after {
    height: 2.3px;
  }
}
/* Small devices (phones) */
@media (max-width: 767px) {
  .LearnMore {
    height: 100%;
    padding: 24px 0;
  }
  .LearnMore h1 {
    font-size: 24px;
  }
  .LearnMore p {
    font-size: 14px;
  }
  .solution__boxes {
    flex-direction: column;
  }
  .solution__box h2 {
    font-size: 18px;
    font-weight: 600;
  }
  .solution__box {
    height: 100%;
    width: 90%;
    padding: 24px;
    margin: 8px 2px;
  }
  .solution__box li a {
    font-size: 14px;
  }
  .solution__box button {
    font-size: 12px;
  }
  .underlined-text::after {
    height: 2.3px;
  
  }
  .underlined-text.is-visible::after {
    width: 102px;
  }
}
