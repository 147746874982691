.Footer {
  background-color: rgb(22, 22, 22);

  height: 100%;
  color: var(--text-color-white);
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
}
.info__left-side {
  background-color: var(--secondary-color);
  height: 100px;
  width: 1200px;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  letter-spacing: 1.2px;
}
.Footer img {
  width: 35px;
  height: 35px;
  object-fit: contain;
  display: flex;
  align-items: center;
  justify-content: center;
}
.info__left-side p {
  margin-right: 36px;
}

.info__right-side {
  background-color: #212121;
  flex-grow: 1;
  height: 100px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}
.info__right-side p {
  font-size: 11px;
  font-weight: 500;
  letter-spacing: 2px;
  transition: all 100ms ease;
  cursor: pointer;
}
.info__right-side p:hover {
  color: var(--secondary-color);
}
.copyright {
  font-size: 11px;
  text-align: center;
  color: #555555;
  margin-top: 36px;
}
.infos {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.footer__logo {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}
.footer__logo h3 {
  font-size: 32px;
  font-weight: 600;
  margin-left: 12px;
  font-family: 'Poppins';
}
.footer__left-side {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;

  width: 1200px;
  height: 100%;
  padding: 12px;
  margin-left: 32px;
}
.footer__left-side h4 {
  margin-top: 18px;
  font-size: 18px;
  color: #d0d0d0;
  font-weight: 300;
}
.footer__left-wrapper {
  width: fit-content;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
}
.footer__socials {
  margin-top: 38px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.footer__right-side {
  flex-grow: 1;
  width: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  flex-direction: column;
  height: 100%;
  margin-right: 36px;
  padding: 12px;
}
.footer__infos {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 12px;
  width: 100%;
  padding: 32px;
}
.Footer input {
  background-color: transparent;
  border-top: 0;
  border-right: 0;
  border-left: 0;
  border-bottom: 2.4px solid white;
  min-width: 350px;
  flex-grow: 1;
  font-weight: 300;
  padding: 8px;
  outline: none;
  overflow: hidden;
  border-radius: 0;
  
}
.Footer .social {
  margin: 0 20px;
  font-size: 20px;
  cursor: pointer;
  transition: all 100ms ease;
}
.Footer .social:hover {
  color: var(--secondary-color);
}
.Footer input::placeholder {
  color: #888888;
  font-weight: 300;

}
.copyright__mobile{
    display: none;
}
.Footer button {
  margin-left: 24px;
  letter-spacing: 2px;
  border: 2px solid white;
  background-color: transparent;
  padding: 8px 68px;
  transition: all 100ms ease;
}
.Footer button:hover{
    color: var(--text-color-dark);
    background: white;
}
.footer__right-side p {
  font-size: 12px;
  text-align: center;
  color: #555555;
  margin-top: 36px;
}

.newsletter {
  display: flex;
  align-items: center;
  justify-content: center;
}
@media (min-width: 992px) {
  .Footer{
    max-height: 400px;
  }
}
/* Medium devices (phones) */
@media (max-width: 991px) {
    .Footer{
        overflow: hidden;
    }
  .infos {
    flex-direction: column;
    height: 100%;
  }
  .info__left-side {
    justify-content: center;
    font-size: 12px;
    margin: 0;
    width: 100%;
  }
  .info__left-side p{
    margin: 0 12px;
  }
  .info__right-side {
    flex-direction: column;
    height: 100%;
    padding: 24px;
   
    
  }
  .info__right-side p {
    margin: 12px 0;
   
  }
  .footer__infos {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .Footer input{
    width: 300px !important;
    text-align: center;
    font-weight: 300;
    font-size: 13.5px;
  }

 
  .newsletter button{
    padding: 8px 22px;
    font-size: 16px;
    margin-top: 32px;
    margin-left: 0;
  }
  .footer__left-side {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0;
  }
  .footer__left-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .footer__right-side {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0;
    
  }
  .Footer .footer__logo h3{
    font-size: 28px;
    margin: 0;
    margin-left: 4px;
  }
  .footer__left-side h4{
    font-size: 15px;
  }
  .newsletter{
    flex-direction: column;
  }
  .footer__socials{
    margin-top: 48px;
  }
 
  .copyright{
    display: none;
  }
  .copyright__mobile{
    display: block;
  }
}
