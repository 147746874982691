/* Advertiser Nav */

nav,
.advertiser-nav {
  width: 100%;
  height: 90px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: white;

  color: var(--text-color-dark);

  z-index: 9999 !important;
  transition: background-color 0.3s ease;
  transition: height 0.3s ease;
  will-change: background-color;

  /* text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.8); */
}

/* Brand Nav */

nav,
.brand-nav {
  width: 100%;
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--brand-color);
  color: var(--text-color-white);
  position: absolute;
  z-index: 9999 !important;
  transition: background-color 0.3s ease;
  will-change: background-color;
}

nav.scrolled {
  position: fixed;

  background-color: white;

  color: var(--text-color-dark);
  text-shadow: none;
  height: 80px;
}

.nav-wrapper {
  width: 100%;
  height: 100%;
  max-width: 1400px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.nav-wrapper img {
  width: 40px;
  height: 40px;
  object-fit: contain;
}

.left-side {
  display: flex;
  align-items: center;
  justify-content: center;
}

.nav-elements {
  display: flex;

  align-items: center;
  justify-content: center;
}

.nav-elements p {
  margin: 0 16px 0 16px;
  font-weight: 200;
  font-size: 16px;
  font-family: "Bricolage Grotesque";

}

.right-side #sign-in {
  font-weight: 400;
  cursor: not-allowed;
}

.right-side {
  display: flex;
  align-items: center;
  justify-content: center;
}

.right-side p {
  font-size: 16px;
  font-weight: normal;
  font-family: "Inter";
}

.right-side #toggle {
  font-size: 32px;
  cursor: not-allowed;

  font-weight: 400;

  color: var(--text-color-dark);
  margin-right: 4px;
}

.nav-elements p,
.right-side p {
  cursor: pointer;
}

.nav-elements p::after,
.right-side p::after {
  content: "";
  position: absolute;
  height: 2px;
  bottom: -4px;
  left: 0;
  width: 0%;
  background: var(--text-color-dark);
  transition: all 200ms ease;
}

nav.scrolled .nav-elements p::after,
nav.scrolled .right-side p::after {
  background: var(--text-color-dark);
}

nav.scrolled p:hover,
.right-side p:hover {
  color: var(--text-color-dark);
}

nav.scrolled #toggle {
  color: var(--text-color-dark);
}

.nav-elements p:hover::after,
.right-side p:hover::after {
  width: 100%;
  right: 0;
}

.nav-elements p,
.right-side p {
  position: relative;
  transition: all 200ms ease;
}

/* .nav-elements p:hover, .right-side p:hover {
    --tw-text-opacity: 1;
    color: var(--text-color-white);
  } */
#toggle {
  transition: transform 0.3s ease-in-out;
}

#toggle:focus {
  scale: 0.6;
}

.loading-overlay img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

nav .logo {
  margin: 0 12px;
  font-weight: 600;

  font-size: 22px;
  font-family: "Poppins";
}

.nav__burger {
  display: none;


}

/* Hamburger Icon with animation */
.line {
  fill: none;
  stroke: black;
  stroke-width: 6;
  transition: stroke-dasharray 600ms cubic-bezier(0.4, 0, 0.2, 1),
    stroke-dashoffset 600ms cubic-bezier(0.4, 0, 0.2, 1);
}

.line1 {
  stroke-dasharray: 60 207;
  stroke-width: 6;
}

.line2 {
  stroke-dasharray: 60 60;
  stroke-width: 6;
}

.line3 {
  stroke-dasharray: 60 207;
  stroke-width: 6;
}

.opened .line1 {
  stroke-dasharray: 90 207;
  stroke-dashoffset: -134;
  stroke-width: 6;
}

.opened .line2 {
  stroke-dasharray: 1 60;
  stroke-dashoffset: -30;
  stroke-width: 6;
}

.opened .line3 {
  stroke-dasharray: 90 207;
  stroke-dashoffset: -134;
  stroke-width: 6;
}

/* Hamburger Icon with animation */

/* Media Queries */

/* Small desktops */
@media (min-width: 1200px) and (max-width: 1439px) {
  .nav-wrapper {
    max-width: 1200px;
  }
}

/*  Landscape Tablets */
@media (min-width: 992px) and (max-width: 1199px) {
  .nav-wrapper {
    max-width: 950px;
  }
}

/* Medium devices (phones) */
@media (min-width: 768px) and (max-width: 991px) {
  .nav-wrapper {
    max-width: 700px;
  }

  .nav-elements {
    display: none;
  }

  nav,
  .advertiser-nav {
    height: 70px;
  }

  .nav__burger {
    display: block;
  }

  .right-side {
    display: none;
  }

  nav .logo {
    font-size: 18px;
  }

  .nav-wrapper img {
    width: 30px;
  }
}

/* Small devices (phones) */
@media (max-width: 767px) {
  nav,
  .advertiser-nav {
    height: 70px;
  }
  .nav-wrapper {
    width: 90%;
  }

  .nav-elements {
    display: none;
  }

  .nav__burger {
    display: block;
  }

  .right-side {
    display: none;
  }

  nav .logo {
    font-size: 16px;
  }

  .nav-wrapper img {
    width: 25px;
  }
}