.Main{
    
   
  width: 100%;
  
  
  display: flex;
  align-items: center;
  justify-content: center;
  
  color: white;
  position: relative;
height: 100%;
  

}
/* Advertiser Main */
.advertiser-main{
  height: 85vh;
  background: #fff;
  
}
.advertiser-main .main-content button:hover{
  background-color: #166bda;
}

.advertiser-main .main-content button{
  transition: all 300ms ease;
}
/* Brand Main */
.brand-main{
  height: 98vh;
  background: var(--brand-color);
  
}
.brand-main .main-content button:hover{
  background-color: #1cc7aa;
}

.brand-main .main-content button{
  transition: all 300ms ease;
  background-color: #1c9c87;
  
}

.socials{
  position: absolute ;
  opacity: 0.65;
  scale: 3.8;

}
.youtube {
  position: absolute;
  left: 53%;
  
  transform: translateX(-50%);

  rotate: -5deg;
}
.facebook {
  position: absolute;
  left: 80%;
  top: 240px;
  transform: translateX(-50%);

  rotate: -10deg;
}
.tiktok{
  position: absolute;
  left: 85%;
  bottom: 0;
  transform: translateX(-50%);

  rotate: 20deg;
}
.instagram{
  position: absolute;
  left: 15%;
  bottom: 155px;
  transform: translateX(-50%);

  rotate: -50deg;
}
.twitter{
  position: absolute;
  left: 25%;
  top: 250px;
  transform: translateX(-50%);

  rotate: 20deg;
}
/*  */
.dots{
  position: absolute;
  opacity: 0.1;
  z-index: 0 !important;
  border-radius: 100%;
}
.dots-1{
  position:absolute;
  left: 150px;
  top: 0;
  scale: 0.4;
}
.dots-2{
  position:absolute;
  right: 150px;
  bottom: -50px;
  scale: 0.4;
}
.main-content{
   
    width: 100%;
    height: 100%;
    z-index: 999;
    max-width: 1100px;
    position: relative;
}


.main-content img{
  width: 900px;
  height: 900px;
  object-fit: contain;
  z-index: 0 !important;
}

.Main video{
  height: 100%;
  width: 100%;
  object-fit: cover;
  position: relative;
  
 

}
.video-overlay{
  background-color: rgba(0, 0, 0, 0.464);
  height: 100%;
  width: 100%;
 z-index: 99;
  position: absolute;
  
}



.main-content h1{
    font-weight: 800;
    font-size: 56px;
    font-family: 'Poppins';
   
   
    
    
}
.main-content h1 span {
   
    display: block;
  }

 
  .main-content-texts{
    width: max-content;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
  }

  


.Main img{
  z-index: 0 !important;

}

.main__text{
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  z-index: 100;
}

.main__text h1{
  font-size: 46px;
  font-weight: 600;
  color: var(--text-color-white);

  font-family: "Bricolage Grotesque";
  max-width: 600px;
  text-align: center;
}
.main__text button{
  background-color: var(--secondary-color);
  color: var(--text-color-white);
  padding: 12px 24px;
  text-align: center;
  font-size:18px;
  margin-top: 24px;
  font-family: 'Inter';
  font-weight: 400;

  /* border-bottom-right-radius: 20px; */
}
/* Small devices (phones) */
@media (max-width: 767px) {
  .Main h1{
    font-size: 24px;
    text-align: center;
  }
  .Main button{
    padding: 12px 24px;

    font-size: 16px;
  }
}