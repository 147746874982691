@import url('https://fonts.googleapis.com/css2?family=Bricolage+Grotesque:opsz,wght@12..96,200..800&family=Inter:wght@100..900&family=Playfair+Display:ital,wght@0,400..900;1,400..900&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Rubik:ital,wght@0,300..900;1,300..900&display=swap');

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Bricolage Grotesque";
 
  
}
/* Change the text selection color */
::selection {
  background-color:#65a8ff; 
 
}

/* Change the text selection color for Firefox */
::-moz-selection {
  background-color: #65a8ff;
 
}




:root {
  --primary-color: #292929;
  --secondary-color: #2684ff;
  --p-color: #575250;
  --brand-color: #00816c;
  --third-color: #f5f5f5;
  --text-color-white: #fff;
  --text-color-dark: #2d2d2d;
}


input {
  font-family: "Inter";
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Bricolage Grotesque";
}

.loading-overlay {
  position: fixed;
  top: 100%;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 99999;
  opacity: 0;
  transition: top 0.5s, opacity 0.3s;
}

.loading-overlay.show {
  top: 0;
  opacity: 1;
}
.loading-overlay.hide {
  top: 100%;
  opacity: 1;
}
.App.loading {
  background-color: #fff;
}

.background-content.hide {
  opacity: 0;
}
html {
  margin: 0;
  padding: 0;
}

.App {
  position: relative;
  width: 100%;
  height: 100vh;
}
/* .background {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-60%, -40%);
  z-index: 1;
} */
