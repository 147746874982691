.Companies {
    height: max-content;
    background: #fbfbfb;
    width: 100%;
    overflow: hidden;
  }
  
  .Companies h1 {
    font-weight: 800;
    font-size: 48px;
    color: var(--text-color-dark);
  }
  
  .companies-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    height: 100%;
  }
  
  .company img {
    width: 100% !important;
    height: 100px;
    object-fit: contain;
    display: flex;
    align-items: center;
    justify-content: center;
    filter: grayscale(100%) invert(52%) sepia(86%) saturate(3153%) hue-rotate(206deg) brightness(95%) contrast(101%) ;
  
  }
  
  
  
  .slider {
    width: 100% !important;
  }
  
  .slider div {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .carousel-container {
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
    width: 1500px !important;
  }
  
  #clients {
    text-align: center;
    font-weight: 200;
    font-size: 14px;
    margin-top: 12px;
    margin-bottom: 12px;
    font-family: "Inter";
    color: #a0a0a0;
  }
  /* Medium devices (phones) */
@media (min-width: 768px) and (max-width: 991px) {
  .company img{
    width: 60% !important;
    height: 80px;
  }
}
 /* Small devices (phones) */
@media (max-width: 767px) {
  #clients{
    font-size: 12px;
  }
  .company img{
    width: 60% !important;
    height: 80px;
  }

}
 
  
  