.Technologies {
  height: 900px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  color: var(--text-color-dark);
  background: #fff;
}
.technologies__wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 1600px;
  width: 100%;
}
.Technologies h1 {
  font-size: 56px;
  font-weight: 600;
  font-family: 'Poppins';
}

.technologies__left-side {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.technologies__left-side p{
  font-weight: 200;
  font-family: 'Bricolage Grotesque';
}
.Technologies button {
  background-color: var(--secondary-color);
  padding: 12px 42px;
  font-size: 24px;
  margin-top: 32px;
  font-weight: 400;
  font-family: 'Bricolage Grotesque';
  color: var(--text-color-white);
  /* border-bottom-right-radius: 20px; */
}
.Technologies span {
  margin-top: 36px;

  font-size: 20px;
  line-height: 2;
  font-weight: 300;
}
.technologies__right-side {
  width: 100%;
}

.left__side-content {
  display: flex;
  align-items: flex-end;
  justify-content: center;
  flex-direction: column;
  text-align: end;
}

.right__side-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(2, 1fr);
}

.right__side-grid div {
  background-color: white;
  width: 330px;
  height: 330px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  padding: 18px;
}

.right__side-grid div p {
  font-size: 24px;
  
  margin-top: 18px;
  font-weight: 200;
  font-family: 'Bricolage Grotesque';
  line-height: 1.4;
}

.vector {
  font-size: 72px;
  color: var(--secondary-color);
}
.technologies__right-side .right__side-grid div:nth-child(even) {
  background: linear-gradient(to bottom, #f5f5f5, #2684ff10, #2684ff10);
}
.technologies__right-side .right__side-grid div:nth-child(odd) {
  background: #fff;
}

/* Media Queries */

/* Small desktops */
@media (min-width: 1200px) and (max-width: 1439px) {
  .Technologies h1 {
    font-size: 52px;
  }
  .right__side-grid div {
    width: 230px;
    height: 230px;
  }
  .right__side-grid div p {
    font-size: 20px;
  }
}
/*  Landscape Tablets */
@media (min-width: 768px) and (max-width: 1199px) {
  .Technologies {
    height: 100%;
  }
  .technologies__wrapper {
    flex-direction: column;
    margin-top: 24px;
  }
  .left__side-content {
    display: flex;
    align-items: center;
    justify-content: center;

    text-align: center;
    margin: 12px 0;
    width: 100%;
  }
  .Technologies span {
    margin-top: 36px;
    width: 100%;

    font-size: 18px;
    line-height: 2;
    font-weight: 400;
  }
  .Technologies h1 {
    font-size: 42px;
  }

  .right__side-grid div p {
    font-size: 18px;
  }
  .right__side-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(2, 1fr);
    margin-top: 24px;
  }
  .right__side-grid div {
    width: 100%;
  }
}
/* Medium devices (phones) */
@media (min-width: 768px) and (max-width: 991px) {
  .Technologies {
    height: 100%;
  }
  .technologies__wrapper {
    flex-direction: column;
    margin-top: 24px;
  }
  .left__side-content {
    display: flex;
    align-items: center;
    justify-content: center;

    text-align: center;
    margin: 12px 0;
    width: 100%;
  }
  .Technologies span {
    margin-top: 36px;
    width: 100%;

    font-size: 16px;
    line-height: 2;
    font-weight: 400;
  }
  .Technologies h1 {
    font-size: 36px;
  }

  .right__side-grid div p {
    font-size: 16px;
  }
  .right__side-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(2, 1fr);
    margin-top: 24px;
  }
  .right__side-grid div {
    width: 100%;
  }
}
/* Small devices (phones) */
@media (max-width: 767px) {
  .Technologies {
    height: 100%;
  }
  .technologies__wrapper {
    flex-direction: column;
    margin-top: 24px;
  }
  .left__side-content {
    display: flex;
    align-items: center;
    justify-content: center;

    text-align: center;
    margin: 12px 0;
    width: 100%;
  }
  .Technologies span {
    margin-top: 36px;
    width: 100%;

    font-size: 14px;
    line-height: 2;
    font-weight: 400;
  }
  .Technologies h1 {
    font-size: 24px;
  }

  .right__side-grid div p {
    font-size: 14px;
  }
  .right__side-grid {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-template-rows: repeat(1, 1fr);
    margin-top: 24px;
  }
  .right__side-grid div {
    width: 100%;
  }
  .Technologies button{
    font-size: 14px;
  }
  .technologies__right-side .right__side-grid div:nth-child(odd) {
    background: linear-gradient(to bottom, #f5f5f5, #2684ff10, #2684ff10);
  }
  .technologies__right-side .right__side-grid div:nth-child(even) {
    background: #fff;
  }
  .vector{
    font-size: 48px;
  }
}
