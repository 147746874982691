

.modal-overlay {
    outline: none;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(71, 71, 71, 0.274);
    z-index: 9999;
    flex-grow: 1;
    animation: fade-in 0.2s ease;
  }

  .modal {
    width: 620px;
    min-height: 750px;
    height: fit-content;
    background-color: white;

    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 9999;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 12px;
    outline: none;
    color: var(--text-color-dark);
    box-shadow: 0px 2.98px 7.46px 0px rgba(0, 0, 0, 0.1);
    animation: fade-in 0.2s ease;
    padding: 48px;
   
  }

  .modal form{
    width: 100%;
  }

.modal h1{
    font-weight: 500;
    font-size: 46px;

}

.modal p{
    font-weight: 300;
    margin: 1.4rem 0;
}

.modal input{
    border: 1px solid grey;
    padding: 0.4rem 0.5rem;
    width: 100%;

   
    font-weight: 300;
    
}
.modal h4{
    margin-top: 2rem;

}

.modal button{
    background-color: var(--text-color-dark);
    color: var(--text-color-white);
    margin-top: auto;
    width: 100%;
    padding: 0.8rem;

    margin-top: 3rem;
}

#exit-btn{
    position: absolute;
    right: 0;
    top: 0;
    font-size: 32px;
    margin-right: 2rem;
    margin-top: 2rem;
    cursor: pointer;

}

.modal textarea{
    border: 1px solid grey;
    padding: 0.4rem 0.5rem;
    width: 100%;
    
    
    font-weight: 300;
    resize: none;
    height: 150px;
}

.form-group span{
    color: red;
    font-size: 14px;
    font-weight: 300;
    margin:none
}

#asterisk{
    font-size: 16px;
}
.captcha{
    margin-top: 1.5rem;
}

.progress-bar {
    height: 10px;
    background: var(--secondary-color);
    width: 0; /* Initially set to 0% width */
    position: absolute;
    left: 0;
    bottom:0;
   
    animation: width 1.5s ease; /* Transition for width property */
    transition: all 1.5s ease;
}

.progress-bar-visible {
    width: 0%; 
}

.modal .group-2 h4{
    margin-top: 1rem;
}

@keyframes width{
    from {
        width: 0%;
    }
    to{
        width: 50%;
    }
}


@keyframes fade-in {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  /* Small devices (phones) */
@media (max-width: 767px) {
    .modal h1{
        font-size: 34px;

    }
    .modal p{
      
        margin: 0.4rem 0;
    }
    .modal h4{
       margin-top: 0.2rem;
        font-size: 14px;
    }
    .modal .group-2 h4{
        margin-top: 1px;
    }
    #exit-btn{
        margin-top: 8rem;
        margin-right: 2rem;
    }
    .form-group span{
        
        font-size: 10px;
       
    }
    .modal input{
       
        padding: 0.1rem 0.3rem;
     
        
    }
    .modal{
        width: 100%;
        height: 80% !important;
        align-items: center;
        justify-content: center;
       
        
    }
   
    .modal textarea{
        height: 50px;
    }
    .captcha{
        padding: 0;
    }
    .modal button{
        margin-top: 1rem;
    }

}