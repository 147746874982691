.Clients{
    height: 1000px;
    background: #f8f8f8;
    background-size:auto;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--text-color-white);
}
.clients__content{
    max-width: 1400px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 100%;


}
.clients__content h1{
    font-weight: 600;
    font-size: 56px;
    color: var(--text-color-dark);
    font-family: Poppins;

}
.clients__content span{
    font-weight: 600;
    font-size: 56px;
    color: var(--secondary-color);
    font-family: 'Poppins';
}
.clients__content p{
    font-weight: 200;
    font-size: 20px;
    margin-top: 24px;
    color: var(--text-color-dark);
    font-family: 'Bricolage Grotesque';
}

.Clients .client img{
    width: 130px;
    height: 130px;
    object-fit: contain;
}
.Clients .client__logos{
    margin-top: 32px;
    max-width: 1100px;
    width: 100%;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    /* background: #f1f7ff; */
    padding: 24px;
    
   
   
}
.client{
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 24px 0;
}

/*  Landscape Tablets */
@media (min-width: 992px) and (max-width: 1199px) {
    .Clients .client__logos{
        grid-template-columns: repeat(3, 1fr);
        padding: 12px;
        background-color: transparent;
        box-shadow: none;
    }
    .Clients h1{
        font-size: 42px;
    }
    .Clients span{
        font-size: 42px;
    }
    .Clients p{
        font-size: 18px;
    }
        
}
/* Medium devices (phones) */
@media (min-width: 768px) and (max-width: 991px) {
    .Clients .client__logos{
        grid-template-columns: repeat(3, 1fr);
        padding: 12px;
        background-color: transparent;
        box-shadow: none;
    }
    .Clients h1{
        font-size: 36px;
    }
    .Clients span{
        font-size: 36px;
    }
    .Clients p{
        font-size: 16px;
    }
}
/* Small devices (phones) */
@media (max-width: 767px) {
    .Clients .client__logos{
        grid-template-columns: repeat(3, 1fr);
        padding: 12px;
        background-color: transparent;
        box-shadow: none;
    }
    .Clients h1{
        font-size: 24px;
    }
    .Clients span{
        font-size: 24px;
    }
    .Clients p{
        font-size: 14px;
    }
    .Clients .client img{
        width: 80px;
        height: 80px;
        object-fit: contain;
    }
    .Clients{
      
        height: 900px;
        padding: 12px 0;
    }
}